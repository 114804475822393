<template>
  <div class="table-view auth-form">

    <div v-if="bLoading" class="row mb-3 text-center center">
      <div class="col-sm-12">
        <div class="card mb-3">
          <div class="card-body">
            <VueSpinnerPie size="100" color="#B2A4FF" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="ErrorMessage" class="alert alert-danger">{{ ErrorMessage }}</div>

    <div v-if="Booking" class="card mb-3">
      <h5 class="card-header">Passenger Information</h5>
      <div class="card-body">

        <div v-if="ErrorMessage" class="row">
          <div class="col-sm-12">
            <div class="alert alert-danger">{{ErrorMessage}}</div>
          </div>
        </div>

        <div class="row">

          <table class="table table-border-prim">
          <thead>
            <tr>              
              <th>Full Name</th>
              <th>Nationality</th>
              <th>Age Type</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>Doc Type</th>
              <th>Doc Number</th>
              <th>Doc Expiry</th>
              <th>Doc Issued</th>                   
            </tr>
          </thead>
          <tbody>
            <tr :key="'pax' + iLoop" v-for="(Pax, iLoop) in Booking.FBReqObject.passengers">
              <td>{{ Pax.name }}</td>
              <td>{{ Pax.nationality }}</td>

              <td v-if="Pax.ageType === '0'"><button type="button" class="w-100 btn btn-color-1">Adult</button></td>
              <td v-else-if="Pax.ageType === '1'"><button type="button" class="w-100 btn btn-color-2">Child</button></td>
              <td v-else>{{ Pax.ageType }}</td>

              <td v-if="Pax.gender === 'M'"><button type="button" class="w-100 btn btn-color-1">Male</button></td>
              <td v-else-if="Pax.gender === 'F'"><button type="button" class="w-100 btn btn-color-2">Female</button></td>
              <td v-else>{{ Pax.gender }}</td>

              <td>{{ dayjs(Pax.birthday).format("DD MMMM YYYY") }}</td>

              <td v-if="Pax.cardType === 'PP'">Passport</td>
              <td v-else-if="Pax.cardType === 'ID'">Chinese ID Card</td>
              <td v-else>{{ Pax.cardType }}</td>

              <td>{{ Pax.cardNum }}</td>
              <td>{{ dayjs(Pax.cardExpired).format("DD MMMM YYYY") }}</td>
              <td>{{ Pax.cardIssuePlace }}</td>
            </tr>
          </tbody>
        </table>

        </div>

      </div>
    </div>  


    <div v-if="Booking" class="card mb-3">
      <div class="card-header">
        <table class="table table-border-prim">
          <thead>
            <tr>              
              <th>Departure</th>
              <th>Route</th>
              <th>Return</th>
              <th>Adult EA</th>
              <th>Adult Tax EA</th>
              <th>Child EA</th>
              <th>Child Tax EA</th>
              <th>Adults</th>
              <th>Childs</th>              
              <th>Policy</th>
              <th>Fare</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dayjs(Booking.FBReqObject.DepartureDate).format("DD MMMM YYYY") }}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-sm btn-color-1">{{ Booking.FBSearchData.DepartureAirport }}</button>                  
                  <button type="button" class="btn btn-sm btn-color-2">{{ Booking.FBSearchData.ArrivalAirport }}</button>
                </div>
              </td>
              <td><span v-if="Booking.FBSearchData.ReturnDate">{{ dayjs(Booking.FBSearchData.ReturnDate).format("DD MMMM YYYY") }}</span></td>
              <td>{{ Booking.FBResObject.routing.adultPrice }}</td>
              <td>{{ Booking.FBResObject.routing.adultTax }} <span v-if="Booking.FBResObject.routing.adultTaxType === 0" class="badge rounded-pill badge-bg-prim">Excl</span> <span v-else-if="Booking.FBResObject.routing.adultTaxType === 1" class="badge rounded-pill badge-bg-sec">Incl</span> <span v-else class="badge rounded-pill badge-bg-primary">{{ Booking.FBResObject.routing.adultTaxType }}</span></td>
              <td>{{ Booking.FBResObject.routing.childPrice }}</td>
              <td>{{ Booking.FBResObject.routing.childTax }} <span v-if="Booking.FBResObject.routing.childTaxType === 0" class="badge rounded-pill badge-bg-prim">Excl</span> <span v-else-if="Booking.FBResObject.routing.childTaxType === 1" class="badge rounded-pill badge-bg-sec">Incl</span> <span v-else class="badge rounded-pill badge-bg-primary">{{ Booking.FBResObject.routing.childTaxType }}</span></td>
              <td>{{ Booking.FBSearchData.NumberAdults }}</td>
              <td>{{ Booking.FBSearchData.NumberChilds }}</td>

              <td v-if="Booking.FBReqObject.routing.policyCategory === 0"><span class="badge rounded-pill bg-warning text-dark">Special Offer</span></td>
              <td v-else-if="Booking.FBReqObject.routing.policyCategory === 1"><span class="badge rounded-pill bg-primary">Standard</span></td>
              <td v-else-if="Booking.FBReqObject.routing.policyCategory === 2"><span class="badge rounded-pill badge-bg-prim">Special Product</span></td>
              <td v-else>{{ Booking.FBReqObject.routing.policyCategory }}</td>

              <td v-if="Booking.FBReqObject.routing.productType === 'PUB'"><span class="badge rounded-pill bg-light text-dark">Public</span></td>
              <td v-else-if="Booking.FBReqObject.routing.productType === 'PRV'"><span class="badge rounded-pill bg-dark">Private</span></td>
              <td v-else>{{ Booking.FBReqObject.routing.productType }}</td> 
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">

        <div class="row">

          <div class="col-sm-3 mb-3">
            <div class="card border-prim">
              <div class="card-body">                            
                <h4>Total Cost: <span class="text-color-prim">{{ Booking.FBTotalPrice }} USD</span></h4>
              </div>
            </div>
          </div> 

          <div class="col-sm-3 mb-3">
            <div class="card border-prim">
              <div class="card-body">                            
                <h4>Validating Carrier: <span class="text-color-prim">{{ Booking.FBResObject.routing.validatingCarrier }}</span></h4>
              </div>
            </div>
          </div> 

          <div v-if="Booking.FBStatus === 'PAYMENTDUE'" class="col-sm-3 mb-3">
            <div class="card border-prim">
              <div class="card-body">            
                <h4 v-if="CalculateTTL(Booking.FBDateTime, Booking.FBResObject.routing.ticketTimeLimit) > 1">TTL Remaining: <span class="text-color-prim">{{ CalculateTTL(Booking.FBDateTime, Booking.FBResObject.routing.ticketTimeLimit) }} Minutes</span></h4>                
                <h4 v-else><span class="text-danger">{{ 'TTL Expired ' + CalculateTTL(Booking.FBDateTime, Booking.FBResObject.routing.ticketTimeLimit) }} Minutes Ago</span></h4>                
              </div>
            </div>
          </div>

          <div v-if="Booking.FBStatus === 'PAYMENTDUE'" class="col-sm-3 mb-3">
            <div class="card border-prim">
              <div class="card-body">            
                <h4 v-if="CalculateValidity(Booking.FBDateTime) > 0">Payment Due In: <span class="text-color-prim">{{ CalculateValidity(Booking.FBDateTime) }} Minutes</span></h4>                
                <h4 v-else><span class="text-danger">{{ 'Booking Expired ' + CalculateValidity(Booking.FBDateTime) }} Minutes Ago</span></h4>                
              </div>
            </div>
          </div>

           

        </div>

        <div class="row">

          <div class="col-sm-3 mb-3">
            <div class="card border-prim">
              <div class="card-body">  
                <h4 v-if="Booking.FBStatus === 'PAYMENTDUE'">Booking Status: <span class="text-color-prim">Payment Due</span></h4>                          
                <h4 v-else-if="Booking.FBStatus === 'PENDING'">Booking Status: <span class="text-success">Pending Issuance</span></h4>  
                <h4 v-else-if="Booking.FBStatus === 'ISSUED'">Booking Status: <span class="text-success">Confirmed/Issued</span></h4>  
                <h4 v-else-if="Booking.FBStatus === 'CANCELLED'">Booking Status: <span class="text-warning">Cancelled</span></h4> 
                <h4 v-else-if="Booking.FBStatus === 'PROBLEM'">Booking Status: <span class="text-danger"></span>Problem</h4>
                <h4 v-else>Booking Status: <span class="text-color-prim">{{ Booking.FBStatus }}</span></h4>                     
              </div>
            </div>
          </div> 

          <div class="col-sm-6 mb-3">
            <div class="card border-prim">              
              <div class="card-body">  
                <h4><span class="text-color-prim">{{ Booking.FBAdminMessage }}</span></h4>
              </div>
            </div>
          </div>

        </div>
                          

        <br>

        <h5 class="card-title text-color-prim">Departure Segments</h5>
        <table class="table table-bordered table-border-prim">
          <thead>
            <tr>
              <th>Segment #</th>
              <th>Carrier</th>
              <th>Flight</th>              
              <th>Departure Time</th>
              <th>Departure Airport</th>
              <th>Arrival Time</th>
              <th>Arrival Airport</th>              
              <th>Cabin</th>
              <th>Duration</th>
              <th>Fare Basis</th>
              <th>Seats Left</th>
              <th>Stops</th>              
            </tr>
          </thead>
          <tbody>
            <tr class="table-row-hover" :class="DS.codeShare ? 'color-bg-2' : ''" v-for="(DS, iLoop) in Booking.FBResObject.routing.fromSegments" :key="iLoop + DS.flightNumber">
              <th>{{ iLoop + 1 }}</th>
              <td>{{ DS.carrier }}</td>
              <td>{{ DS.flightNumber }} <span class="badge badge-bg-sec">{{ DS.operatingFlightNumber }}</span></td>
              <td>{{ dayjs(DS.depTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(DS.depTime).format("HH mm") }}</span></td>
              <td>{{ DS.depAirport }}</td>
              <td>{{ dayjs(DS.arrTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(DS.arrTime).format("HH mm") }}</span></td>
              <td>{{ DS.arrAirport }}</td>
              
              <td v-if="DS.cabinGrade === 'Y'">{{ DS.cabin }} <span class="badge rounded-pill badge-bg-prim">Economy</span></td>
              <td v-else-if="DS.cabinGrade === 'F'">{{ DS.cabin }} <span class="badge rounded-pill bg-danger">First</span></td>
              <td v-else-if="DS.cabinGrade === 'C'">{{ DS.cabin }} <span class="badge rounded-pill bg-warning">Business</span></td>
              <td v-else>{{ DS.cabin }} | {{ DS.cabinGrade }}</td>

              <td>{{ CalculateDuration(DS.duration) }}</td>
              <td>{{ DS.fareBasis }}</td>
              <td>{{ DS.seatsRemain }}</td>            
              <td>{{ DS.stops }}</td>
            </tr>
          </tbody>
        </table>

        <h5 v-if="Booking.FBResObject.routing.retSegments.length > 0" class="card-title text-color-sec">Return Segments</h5>
        <table v-if="Booking.FBResObject.routing.retSegments.length > 0" class="table table-bordered table-border-prim">
          <thead>
            <tr>
              <th>Segment #</th>
              <th>Carrier</th>
              <th>Flight</th>              
              <th>Departure Time</th>
              <th>Departure Airport</th>
              <th>Arrival Time</th>
              <th>Arrival Airport</th>              
              <th>Cabin</th>
              <th>Duration</th>
              <th>Fare Basis</th>
              <th>Seats Left</th>
              <th>Stops</th>              
            </tr>
          </thead>
          <tbody>
            <tr class="table-row-hover" :class="RS.codeShare ? 'color-bg-2' : ''" v-for="(RS, iLoop) in Booking.FBResObject.routing.retSegments" :key="iLoop + RS.flightNumber">
              <th>{{ Booking.FBResObject.routing.fromSegments.length + (iLoop + 1) }}</th>
              <td>{{ RS.carrier }}</td>
              <td>{{ RS.flightNumber }} <span class="badge badge-bg-sec">{{ RS.operatingFlightNumber }}</span></td>
              <td>{{ dayjs(RS.depTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(RS.depTime).format("HH mm") }}</span></td>
              <td>{{ RS.depAirport }}</td>
              <td>{{ dayjs(RS.arrTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(RS.arrTime).format("HH mm") }}</span></td>
              <td>{{ RS.arrAirport }}</td>

              <td v-if="RS.cabinGrade === 'Y'">{{ RS.cabin }} <span class="badge rounded-pill badge-bg-prim">Economy</span></td>
              <td v-else-if="RS.cabinGrade === 'F'">{{ RS.cabin }} <span class="badge rounded-pill bg-danger">First</span></td>
              <td v-else-if="RS.cabinGrade === 'C'">{{ RS.cabin }} <span class="badge rounded-pill bg-warning">Business</span></td>
              <td v-else>{{ RS.cabin }} | {{ RS.cabinGrade }}</td>
              
              <td>{{ CalculateDuration(RS.duration) }}</td>
              <td>{{ RS.fareBasis }}</td>
              <td>{{ RS.seatsRemain }}</td>            
              <td>{{ RS.stops }}</td>
            </tr>
          </tbody>
        </table>        


      </div>
    </div>

    <div v-if="Booking" class="card mb-3">
      <div class="card-header h4">Flight Rules</div>
      <div class="card-body">

        <div class="accordion" :id="'RulesAccordian' + iNum">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingOne' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + iNum" aria-expanded="false" aria-controls="flush-collapseOne">Baggage Rules</button>
            </h2>
            <div :id="'collapseOne' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingOne' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Segment</th>
                      <th>Adult Quantity</th>
                      <th>Adult Weight</th>
                      <th>Child Quantity</th>
                      <th>Child Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="B in Booking.FBRules.baggage" :key="iNum + 'bag' + B.segmentNo">                      
                      <td>{{ B.segmentNo }}</td>
                      <td>{{ B.adultQuantity }}</td>
                      <td>{{ B.adultWeight }}</td>
                      <td>{{ B.childQuantity }}</td>
                      <td>{{ B.childWeight }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingTwo' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + iNum" aria-expanded="false" aria-controls="flush-collapseTwo">Change Rules</button>
            </h2>
            <div :id="'collapseTwo' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingTwo' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">USD Fee</th>
                      <th scope="col">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(C, iLoop) in Booking.FBRules.changes" :key="iNum + 'change' + iLoop">

                      <td v-if="C.changeType === 0">All Unused</td>
                      <td v-else-if="C.changeType === 1">Partial Used</td>
                      <td v-else-if="C.changeType === 2">No-Show Before Takeoff</td>
                      <td v-else-if="C.changeType === 3">No-Show After Takeoff</td>
                      <td v-else>{{ C.changeType }}</td>

                      <td class="text-danger" v-if="C.changeStatus === 'T'">Non Refundable</td>
                      <td class="text-primary" v-else-if="C.changeStatus === 'H'">Conditions Apply</td>
                      <td class="text-success" v-else-if="C.changeStatus === 'F'">Refundable</td>
                      <td v-else-if="C.changeStatus === 'E'">Airline Rules</td>

                      <td>{{C.changeFee}}</td>
                      <td>{{ C.remark }}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingThree' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseThree' + iNum" aria-expanded="false" aria-controls="flush-collapseThree">Refund Rules</button>
            </h2>
            <div :id="'collapseThree' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingThree' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">USD Fee</th>
                      <th scope="col">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(R, iLoop) in Booking.FBRules.refunds" :key="iNum + 'refund' + iLoop">

                      <td v-if="R.refundType === 0">All Unused</td>
                      <td v-else-if="R.refundType === 1">Partial Used</td>
                      <td v-else-if="R.refundType === 2">No-Show Before Takeoff</td>
                      <td v-else-if="R.refundType === 3">No-Show After Takeoff</td>
                      <td v-else>{{ R.refundType }}</td>

                      <td class="text-danger" v-if="R.refundStatus === 'T'">Non Refundable</td>
                      <td class="text-primary" v-else-if="R.refundStatus === 'H'">Conditions Apply</td>
                      <td class="text-success" v-else-if="R.refundStatus === 'F'">Refundable</td>
                      <td v-else-if="R.refundStatus === 'E'">Airline Rules</td>

                      <td>{{ R.refundFee }}</td>
                      <td>{{ R.remark }}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingFour' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFour' + iNum" aria-expanded="false" aria-controls="flush-collapseFour">Other Information</button>
            </h2>
            <div :id="'collapseFour' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingFour' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <ul class="list-group">

                  <li v-if="Booking.FBReqObject.routing.nationalityType === 0" class="list-group-item">Suitable For All Nationalities</li>
                  <li v-else-if="Booking.FBReqObject.routing.nationalityType === 1" class="list-group-item">Suitable For The Below Nationalities</li>
                  <li v-else-if="Booking.FBReqObject.routing.nationalityType === 2" class="list-group-item list-group-item-danger">Not Suitable For The Below Nationalities</li>
                  <li v-else class="list-group-item">Nationality Type: {{ FD.nationalityType }}</li>

                  <li v-if="Booking.FBReqObject.routing.nationality" class="list-group-item">Nationalities: {{ Booking.FBReqObject.routing.nationality }}</li>

                  <li v-if="Booking.FBReqObject.routing.suitAge" class="list-group-item">Suitable Ages: {{ Booking.FBReqObject.routing.suitAge }}</li>
                  <li v-else class="list-group-item list-group-item-success">Suitable For All Ages</li>

                </ul>
              </div>
            </div>
          </div>




        </div>


      </div>
    </div>


    <div v-if="Booking && !bIssueTicketAllowed" class="card mb-3">
      <div class="card-header h4 text-danger">This booking can not be issued</div>
      <div class="card-body"> 
        <h5 class="card-title">{{ NoIssueReason }}</h5>
      </div>
    </div>

    <div v-else-if="Booking && bIssueTicketAllowed" class="card mb-3">
      <div class="card-header h4 text-color-prim">Ready To Issue Ticket?</div>
      <div class="card-body">

        <div class="row">

          <div v-if="!bHasCredit" class="alert alert-warning h5">
            Your travel agency credit has been exhausted, please contact support to add credit.
          </div>

          <div v-else>

            <div class="alert alert-warning">The booking will now be issued after this step. Please confirm if all of the details are correct. Any cancellations or changes may be subject to a fee</div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="Input_Agree">
              <label v-if="!Input_Agree" class="form-check-label text-danger">I confirm that all the details are correct</label>
              <label v-else class="form-check-label text-color-prim">I confirm that all the details are correct</label>
            </div>

            <br>

            <button ref="IssueTicketButton" @click="HandleIssueTicket" :disabled="!Input_Agree" type="button" class="btn btn-color-1">
              <span v-if="bLoading2"><VueSpinnerInfinity size="30"></VueSpinnerInfinity> Please Wait...</span>
              <span v-else>Issue Ticket</span>
            </button>


          </div>

        </div>
        
    

        
      </div>
    </div>



  </div>
</template>

<script>  

import { computed, onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
import { useRoute } from 'vue-router';
import { UseFlightSearchStore } from "@/stores/FlightSearchStore";
const dayjs = require('dayjs')

import { VueSpinnerPie, VueSpinnerInfinity } from 'vue3-spinners';


export default {
  components: {VueSpinnerPie, VueSpinnerInfinity},
    setup(){ 

      const FlightSearchStore = UseFlightSearchStore()

      const ErrorMessage = ref('')
      const bLoading = ref(true)
      const bLoading2 = ref(false)

      const iNum = 0

      //Route params
      let sPNRCode = useRoute().params.pnr
      let sOrderNo = useRoute().params.order

      const Booking = ref()
      const bIssueTicketAllowed = ref(false)
      const bHasCredit = ref(false)
      const NoIssueReason = ref('')

      const Input_Agree = ref(false)

      const IssueTicketButton = ref(null)

      onMounted(() => {

        //Get the order from api
        axios.get("/api/GetIssuedBooking/" + sPNRCode + "/" + sOrderNo).then((Result) => {
          if(Result.data.Order){
            Booking.value = Result.data.Booking
            bIssueTicketAllowed.value = Result.data.IssueTicketAllowed
            bHasCredit.value = Result.data.HasCredit
            NoIssueReason.value = Result.data.Reason

          }else{
            ErrorMessage.value = "Error. Booking not found or API Error"
          }
          bLoading.value = false
        }).catch((Error) => {
          bLoading.value = false
          if(Error.response.data.Reason){
            ErrorMessage.value = Error.response.data.Reason
          }else{
            ErrorMessage.value = "Error. Booking not found or API Error"
          }
        })
        
    
      })//end mounted

      const CalculateDuration = (Minutes) => {

        const iMinTotal = parseInt(Minutes)

        const iHours =  Math.floor(iMinTotal / 60)
        const iMinutes = iMinTotal % 60

        return iHours + "H & " + iMinutes + "M"

      }

      const CalculateTTL = (DateTime, TTL) => {
        const dBookingExpiry = dayjs(DateTime).add(parseInt(TTL), 'minutes')
        const dTTL = dBookingExpiry.diff(dayjs(), 'minutes')

        return parseInt(dTTL)
      }

      const CalculateValidity = (DateTime) => {
        const dBookingExpiry = dayjs(DateTime).add(parseInt(30), 'minutes')
        const dTTL = dBookingExpiry.diff(dayjs(), 'minutes')

        return parseInt(dTTL)
      }

      const HandleIssueTicket = () => {

        bLoading2.value = true

        const frmPNR = sPNRCode
        const frmOrder = sOrderNo

        axios.post("/api/IssueTicket", {
          frmPNR,
          frmOrder
        }).then((Result) => {
          setTimeout(() => {
            router.go()
          }, 3000);
        }).catch((Error) => {
          console.log(Error)
          if(Result.data.Reason){
            ErrorMessage.value = Result.data.Reason
          }else{
            ErrorMessage.value = "API Error, Please contact support"
          }
          bLoading2.value = false
        })

      }

      const TotalPrice = computed(() => {

        // var dTotal = 0

        // const iNumAdults = parseInt(SearchData.value.NumberAdults)
        // const iNumChilds = parseInt(SearchData.value.NumberChilds) 

        // var dTotalAdults = (parseFloat(VerifiedFlight.value.routing.adultPrice) + parseFloat(VerifiedFlight.value.routing.adultTax)) * parseInt(iNumAdults)
        // var dTotalChilds = (parseFloat(VerifiedFlight.value.routing.childPrice) + parseFloat(VerifiedFlight.value.routing.childTax)) * parseInt(iNumChilds)
        // dTotal = dTotalAdults + dTotalChilds

        // return dTotal

      })
    

      return {      
        ErrorMessage,
        dayjs,
        CalculateDuration,
        TotalPrice,
        bLoading,
        Booking,
        bIssueTicketAllowed,
        CalculateTTL,
        CalculateValidity,
        bHasCredit,
        NoIssueReason,
        Input_Agree,
        IssueTicketButton,
        HandleIssueTicket,
        bLoading2,
        iNum
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.center {
  margin: auto;
  width: 50%;
}

</style>