<template>
  <div>
    <NavBarComponent v-if="$route.path !== '/login' && $route.path !== '/register' && $route.path !== '/forgot-password'"/>
    <router-view/>
  </div>
</template>

<script>

import NavBarComponent from "./components/NavBarComponent.vue";
import axios from "axios";
import {onUpdated, ref} from 'vue'
import router from "@/router";

export default {
  components: { NavBarComponent },

  setup() {  
    
    
    return {}

  },
};
</script>

<style>
body{

  background: #B2A4FF !important;  /* fallback for old browsers */
 
}

.normal-view{
  max-width: 60%;
  margin: auto;    
}

.table-view{
  max-width: 90%;
  margin: auto;
}

.text-color-1{
  color: #FFDEB4;
}

.text-color-2{
  color: #FDF7C3;
}

.text-color-prim{
  color: #B2A4FF;
}

.text-color-sec{
  color: #FFB4B4;
}

.table-border-prim{
  border-color: #B2A4FF !important;
}

.table-bg-prim{
  background-color: #B2A4FF;
}

.badge-bg-prim{
  background-color: #B2A4FF;
}

.badge-bg-sec{
  background-color: #FFB4B4;
  color: black !important;
}

.color-bg-2{
  background-color: #FFDEB4;
}

.btn-color-1{
  background: #B2A4FF !important;  
  border: #B2A4FF !important;
}

.btn-color-2{
  background: #FFB4B4 !important; 
  border: #FFB4B4 !important;
}

.btn-color-3{
  background: #FFDEB4 !important; 
  border: #FFDEB4 !important;
}

.border-prim{
  border-color: #B2A4FF !important;
}

.border-sec{
  border-color: #FFDEB4 !important;
}

@media only screen and (max-width: 600px) {

  .normal-view{
    max-width: 96%;
    margin: auto;    
  }

  .table-view{
    max-width: 96%;
    margin: auto;
  }
}
</style>
