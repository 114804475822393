<template>
  <div class="container-fluid">
    <div class="row no-gutter">
      <!-- The image half -->
      <div class="col-md-6 d-none d-md-flex bg-image"></div>


      <!-- The content half -->
      <div class="col-md-6 content-half">
        <div class="login d-flex align-items-center py-5">

          <!-- Demo content-->
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-xl-7 mx-auto">
                <h3 class="display-4 text-color-1">ABD Flights</h3>
                <p class="mb-4 text-color-2">Flight Booking Engine</p>
                <form @submit.prevent="HandleLogin">
                  <div class="form-group mb-3">
                    <input v-model="Input_Email" type="email" placeholder="Email Address" required autofocus class="form-control rounded-pill shadow-sm px-4">
                  </div>
                  <div class="form-group mb-3">
                    <input v-model="Input_Password" type="password" placeholder="Password" required class="form-control rounded-pill shadow-sm px-4 text-primary">
                  </div>
                  <div v-if="ErrorMessage" class="alert alert-warning">{{ ErrorMessage }}</div>
                  <button ref="LoginButton" type="submit" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">Login</button>
                </form>
              </div>
            </div>
          </div><!-- End -->

        </div>
      </div><!-- End -->

    </div>
  </div>
</template>

<script>

import { onMounted, ref } from "vue"
import axios from "axios"
import router from "@/router"


export default {
  setup() {

    const Input_Email = new ref('')
    const Input_Password = new ref('')
    const ErrorMessage = new ref('')
    const LoginButton = new ref(null)

    const HandleLogin = () => {

      LoginButton.value.disabled = true
      LoginButton.value.textContent = "Authenticating..."
      ErrorMessage.value = ""

      const frmEmail = Input_Email.value
      const frmPassword = Input_Password.value

      axios.post("/api/LoginUser", { frmEmail, frmPassword }).then((Result) => {
        if (Result.data.Success) {
          router.push({ name: 'search'})
        } else {
          ErrorMessage.value = Result.data.Reason
          LoginButton.value.textContent = "Login"
        }
        LoginButton.value.disabled = false
      }).catch((Error) => {
        console.log(Error)
        LoginButton.value.textContent = "Login"
        ErrorMessage.value = "Internal error. Please contact support"
        LoginButton.value.disabled = false
      })


    }

    return {
      Input_Email,
      Input_Password,
      ErrorMessage,
      LoginButton,
      HandleLogin
    }
  }
}
</script>

<style scoped>

.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url('../../assets/login/pic.jpg');
  background-size: cover;
  background-position: center center;
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
</style>