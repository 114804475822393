<template>
  <div class="normal-view auth-form">
    <div class="card">
      <h5 class="card-header">Enter Search Criteria</h5>
      <div class="card-body">

        <div v-if="ErrorMessage" class="alert alert-danger">{{ ErrorMessage }}</div>

        <form @submit.prevent="HandleSearch()">

          <div class="row">
            <div class="col-sm-6 mb-3">             
              <label class="form-label">Depart From</label>
              <SimpleTypeahead
                class="form-control"                
                placeholder="Search For Airport"
                :items="AirportCodeList"
                :minInputLength="3"                
                @selectItem="HandleDepartAirportClick"
                :itemProjection="itemProjectionFunction"
              >
              <template #list-header><span class="text-color-prim">Search By Name Or Part Thereof</span></template>
              </SimpleTypeahead>              
            </div>
            <div class="col-sm-6 mb-3">
              <label class="form-label">Arrive At</label>
              <SimpleTypeahead
                class="form-control"                
                placeholder="Search For Airport"
                :items="AirportCodeList"
                :minInputLength="3"                
                @selectItem="HandleArriveAirportClick"
                :itemProjection="itemProjectionFunction"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 mb-3">
              <label class="form-label">Departure Date</label>
              <flat-pickr ref="DepartureDatePicker" v-model="Input_DepartureDate" class="form-control" :config="dateconfig"></flat-pickr>
            </div>
            <div class="col-sm-6 mb-3">
              <label class="form-label">Return Date</label>              
              <flat-pickr ref="ReturnDatePicker" v-model="Input_ReturnDate" class="form-control" :config="dateconfig"></flat-pickr>
              <small class="text-muted">Leave blank for one-way journey</small>
            </div>            
          </div>

          <div class="row">
            <div class="col-sm-6 mb-3">
              <label class="form-label">Number Of Adults</label>
              <select v-model="Input_Adults" class="form-select">
                <option selected value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>                
              </select>
            </div>
            <div class="col-sm-6 mb-3">
              <label class="form-label">Number Of Children/Infants</label>
              <select v-model="Input_Childs" class="form-select">
                <option selected value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>                
              </select>
              <small class="text-muted">0 - 11 years old</small>
            </div>
          </div>

          <button ref="btnSearch" type="submit" class="btn btn-primary btn-color-1 mb-3">
            <div v-if="bSearchLoading">
              <VueSpinnerClock size="30" color="white" />
            </div>
            <div v-else>Search</div>
          </button>          
        </form>
      </div>
    </div>

  </div>
</template>

<script>

import { computed, onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
import { UseFlightSearchStore } from "@/stores/FlightSearchStore";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

import { VueSpinnerClock } from 'vue3-spinners';

export default {
  components: {flatPickr, SimpleTypeahead, VueSpinnerClock},
    setup(){ 

      const FlightSearchStore = UseFlightSearchStore()
      const Input_DepartAirport = ref('')
      const Input_ArriveAirport = ref('')
      const arrAirportCodes = ref([])
      const Input_DepartureDate = ref('')
      const Input_ReturnDate = ref('')
      const Input_Adults = ref("1")
      const Input_Childs = ref("0")
      const btnSearch = ref(null)
      const bSearchLoading = ref(false)

      const ErrorMessage = ref('')

      onMounted(() => {

        if(FlightSearchStore.AirportCodes.length < 1){
          //Airport codes not loaded, lets load it
          axios.get("/api/GetAirportCodes").then((Result) => {
            arrAirportCodes.value = Result.data.AirportCodes
            FlightSearchStore.AirportCodes = Result.data.AirportCodes          
          }).catch((Error) => {
            console.log(Error.response);
            if(Error.response.data.Reason){
              ErrorMessage.value = Error.response.data.Reason
            }
          })
        }//end if

        //Check if we have search data        
        if(Object.keys(FlightSearchStore.SearchData).length > 0){
          //Load into the search          
          Input_DepartAirport.value = FlightSearchStore.SearchData.DepartureAirport
          Input_ArriveAirport.value = FlightSearchStore.SearchData.ArrivalAirport
          Input_DepartureDate.value = FlightSearchStore.SearchData.DepartureDate
          Input_ReturnDate.value = FlightSearchStore.SearchData.ReturnDate
          Input_Adults.value = FlightSearchStore.SearchData.NumberAdults
          Input_Childs.value = FlightSearchStore.SearchData.NumberChilds
        }
      

      })//end mounted

      const AirportCodeList = computed(() => {

        var arrAC = []

        arrAirportCodes.value.forEach((AC) => {
          const obj = {
            //'name': AC.IATACode + " | " + AC.AirportName + " - " + AC.RegionName + " " + AC.CountryCode,
            'name': AC.IATACode + " | " + AC.AirportName + " | " + AC.RegionName,
            'value': AC.IATACode
          }
          arrAC.push(obj)
        })
        
        return arrAC

      })

      const dateconfig = ref({
        mode: 'single',
        minDate: 'today',
        dateFormat: 'Ymd',        
        altFormat: 'd F Y',
        altInput: true
      })

      const HandleSearch = () => {
      
        ErrorMessage.value = ''        

        if(Input_DepartAirport.value === ""){
          ErrorMessage.value = "Please Select A Departure Airport"
          return
        }

        if(Input_ArriveAirport.value === ""){
          ErrorMessage.value = "Please Select An Arrival Airport"
          return
        }

        if(Input_DepartAirport.value === Input_ArriveAirport.value){
          ErrorMessage.value = "Departure And Arrival Airport Can Not Be The Same!"
          return
        }

        const frmDepartureAirport = Input_DepartAirport.value.toUpperCase()
        const frmArrivalAirport = Input_ArriveAirport.value.toUpperCase()

        if(Input_DepartureDate.value === ""){
          ErrorMessage.value = "Please Select A Departure Date"
          return
        }

        const frmDepartureDate = Input_DepartureDate.value

        //1 = one way and 2 = round trip
        var frmTripType = "1"
        var frmReturnDate = ""

        if(Input_ReturnDate.value !== "" || Input_ReturnDate.value || null){
          //This means the trip is a return trip or round trip
          frmTripType = "2"
          frmReturnDate = Input_ReturnDate.value
        }

        const frmAdults = Input_Adults.value
        const frmChilds = Input_Childs.value    
        
        btnSearch.value.disabled = true        
        bSearchLoading.value = true

        //Put the search into the store
        FlightSearchStore.SearchData.DepartureAirport = Input_DepartAirport.value.toUpperCase()
        FlightSearchStore.SearchData.ArrivalAirport = Input_ArriveAirport.value.toUpperCase()
        FlightSearchStore.SearchData.DepartureDate = Input_DepartureDate.value
        FlightSearchStore.SearchData.ReturnDate = Input_ReturnDate.value
        FlightSearchStore.SearchData.NumberAdults = Input_Adults.value
        FlightSearchStore.SearchData.NumberChilds = Input_Childs.value

        axios.post("/api/SearchFlights", {
          frmDepartureAirport,
          frmArrivalAirport,
          frmDepartureDate,
          frmReturnDate,
          frmTripType,
          frmAdults,
          frmChilds
        }).then((Result) => {                              
          if(Result.data.Flights.length < 1){
            ErrorMessage.value = "Unable to find any routes for the requested search"
            btnSearch.value.disabled = false       
            bSearchLoading.value = false     
          }else{
            FlightSearchStore.FlightRoutings = Result.data.Flights
            router.push({name: 'searchresults'})
          }
          
          
        }).catch((Error) => {
          console.log(Error.response);
          if(Error.response.data.Reason){
            ErrorMessage.value = Error.response.data.Reason
          }
          btnSearch.value.disabled = false          
          bSearchLoading.value = false
        })

        

       
      }//end handleSearch


      const HandleDepartAirportClick = (g) => {
        Input_DepartAirport.value = g.value        
      }

      const HandleArriveAirportClick = (g) => {
        Input_ArriveAirport.value = g.value
      }
    

      const itemProjectionFunction = (e) => {
        return e.name
      }

    

      return {      
        Input_DepartAirport,
        Input_ArriveAirport,
        AirportCodeList,   
        HandleDepartAirportClick,
        HandleArriveAirportClick  ,        
        ErrorMessage,
        itemProjectionFunction,
        dateconfig,
        Input_DepartureDate,
        Input_ReturnDate,
        Input_Adults,
        Input_Childs,
        btnSearch,
        bSearchLoading,
        HandleSearch     
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

</style>