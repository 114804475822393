<template>
  <div class="table-view auth-form">

    <div v-if="ErrorMessage" class="alert alert-danger">{{ ErrorMessage }}</div>
    
    <div v-if="VerifiedFlight" class="card mb-3">
      <div class="card-header">
        <table class="table table-border-prim">
          <thead>
            <tr>              
              <th>Departure</th>
              <th>Route</th>
              <th>Return</th>
              <th>Adult EA</th>
              <th>Adult Tax EA</th>
              <th>Child EA</th>
              <th>Child Tax EA</th>
              <th>Adults</th>
              <th>Childs</th>
              <th>Total USD</th>
              <th>Policy</th>
              <th>Fare</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dayjs(SearchData.DepartureDate).format("DD MMMM YYYY") }}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-sm btn-color-1">{{ SearchData.DepartureAirport }}</button>                  
                  <button type="button" class="btn btn-sm btn-color-2">{{ SearchData.ArrivalAirport }}</button>
                </div>
              </td>
              <td><span v-if="SearchData.ReturnDate">{{ dayjs(SearchData.ReturnDate).format("DD MMMM YYYY") }}</span></td>
              <td>{{ VerifiedFlight.routing.adultPrice }}</td>
              <td>{{ VerifiedFlight.routing.adultTax }} <span v-if="VerifiedFlight.routing.adultTaxType === 0" class="badge rounded-pill badge-bg-prim">Excl</span> <span v-else-if="VerifiedFlight.routing.adultTaxType === 1" class="badge rounded-pill badge-bg-sec">Incl</span> <span v-else class="badge rounded-pill badge-bg-primary">{{ VerifiedFlight.routing.adultTaxType }}</span></td>
              <td>{{ VerifiedFlight.routing.childPrice }}</td>
              <td>{{ VerifiedFlight.routing.childTax }} <span v-if="VerifiedFlight.routing.childTaxType === 0" class="badge rounded-pill badge-bg-prim">Excl</span> <span v-else-if="VerifiedFlight.routing.childTaxType === 1" class="badge rounded-pill badge-bg-sec">Incl</span> <span v-else class="badge rounded-pill badge-bg-primary">{{ VerifiedFlight.routing.childTaxType }}</span></td>
              <td>{{ SearchData.NumberAdults }}</td>
              <td>{{ SearchData.NumberChilds }}</td>

              <td>{{TotalPrice.toFixed(2)}}</td>

              <td v-if="VerifiedFlight.routing.policyCategory === 0"><span class="badge rounded-pill bg-warning text-dark">Special Offer</span></td>
              <td v-else-if="VerifiedFlight.routing.policyCategory === 1"><span class="badge rounded-pill bg-primary">Standard</span></td>
              <td v-else-if="VerifiedFlight.routing.policyCategory === 2"><span class="badge rounded-pill badge-bg-prim">Special Product</span></td>
              <td v-else>{{ VerifiedFlight.routing.policyCategory }}</td>

              <td v-if="VerifiedFlight.routing.productType === 'PUB'"><span class="badge rounded-pill bg-light text-dark">Public</span></td>
              <td v-else-if="VerifiedFlight.routing.productType === 'PRV'"><span class="badge rounded-pill bg-dark">Private</span></td>
              <td v-else>{{ VerifiedFlight.routing.productType }}</td> 
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">

        <div class="row">

          <div class="col-sm-6">
            <div class="card border-prim">
              <div class="card-body">            
                <h4>Ticketing Time Limit: <span class="text-color-prim">{{ VerifiedFlight.routing.ticketTimeLimit }} Minutes</span></h4>                
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="card border-prim">
              <div class="card-body">                            
                <h4>Validating Carrier: <span class="text-color-prim">{{ VerifiedFlight.routing.validatingCarrier }}</span></h4>
              </div>
            </div>
          </div>  

        </div>
                          

        <br>

        <h5 class="card-title text-color-prim">Departure Segments</h5>
        <table class="table table-bordered table-border-prim">
          <thead>
            <tr>
              <th>Segment #</th>
              <th>Carrier</th>
              <th>Flight</th>              
              <th>Departure Time</th>
              <th>Departure Airport</th>
              <th>Arrival Time</th>
              <th>Arrival Airport</th>              
              <th>Cabin</th>
              <th>Duration</th>
              <th>Fare Basis</th>
              <th>Seats Left</th>
              <th>Stops</th>              
            </tr>
          </thead>
          <tbody>
            <tr class="table-row-hover" :class="DS.codeShare ? 'color-bg-2' : ''" v-for="(DS, iLoop) in VerifiedFlight.routing.fromSegments" :key="iLoop + DS.flightNumber">
              <th>{{ iLoop + 1 }}</th>
              <td>{{ DS.carrier }}</td>
              <td>{{ DS.flightNumber }} <span class="badge badge-bg-sec">{{ DS.operatingFlightNumber }}</span></td>
              <td>{{ dayjs(DS.depTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(DS.depTime).format("HH mm") }}</span></td>
              <td>{{ DS.depAirport }}</td>
              <td>{{ dayjs(DS.arrTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(DS.arrTime).format("HH mm") }}</span></td>
              <td>{{ DS.arrAirport }}</td>
              
              <td v-if="DS.cabinGrade === 'Y'">{{ DS.cabin }} <span class="badge rounded-pill badge-bg-prim">Economy</span></td>
              <td v-else-if="DS.cabinGrade === 'F'">{{ DS.cabin }} <span class="badge rounded-pill bg-danger">First</span></td>
              <td v-else-if="DS.cabinGrade === 'C'">{{ DS.cabin }} <span class="badge rounded-pill bg-warning">Business</span></td>
              <td v-else>{{ DS.cabin }} | {{ DS.cabinGrade }}</td>

              <td>{{ CalculateDuration(DS.duration) }}</td>
              <td>{{ DS.fareBasis }}</td>
              <td>{{ DS.seatsRemain }}</td>            
              <td>{{ DS.stops }}</td>
            </tr>
          </tbody>
        </table>

        <h5 v-if="VerifiedFlight.routing.retSegments.length > 0" class="card-title text-color-sec">Return Segments</h5>
        <table v-if="VerifiedFlight.routing.retSegments.length > 0" class="table table-bordered table-border-prim">
          <thead>
            <tr>
              <th>Segment #</th>
              <th>Carrier</th>
              <th>Flight</th>              
              <th>Departure Time</th>
              <th>Departure Airport</th>
              <th>Arrival Time</th>
              <th>Arrival Airport</th>              
              <th>Cabin</th>
              <th>Duration</th>
              <th>Fare Basis</th>
              <th>Seats Left</th>
              <th>Stops</th>              
            </tr>
          </thead>
          <tbody>
            <tr class="table-row-hover" :class="RS.codeShare ? 'color-bg-2' : ''" v-for="(RS, iLoop) in VerifiedFlight.routing.retSegments" :key="iLoop + RS.flightNumber">
              <th>{{ VerifiedFlight.routing.fromSegments.length + (iLoop + 1) }}</th>
              <td>{{ RS.carrier }}</td>
              <td>{{ RS.flightNumber }} <span class="badge badge-bg-sec">{{ RS.operatingFlightNumber }}</span></td>
              <td>{{ dayjs(RS.depTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(RS.depTime).format("HH mm") }}</span></td>
              <td>{{ RS.depAirport }}</td>
              <td>{{ dayjs(RS.arrTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(RS.arrTime).format("HH mm") }}</span></td>
              <td>{{ RS.arrAirport }}</td>

              <td v-if="RS.cabinGrade === 'Y'">{{ RS.cabin }} <span class="badge rounded-pill badge-bg-prim">Economy</span></td>
              <td v-else-if="RS.cabinGrade === 'F'">{{ RS.cabin }} <span class="badge rounded-pill bg-danger">First</span></td>
              <td v-else-if="RS.cabinGrade === 'C'">{{ RS.cabin }} <span class="badge rounded-pill bg-warning">Business</span></td>
              <td v-else>{{ RS.cabin }} | {{ RS.cabinGrade }}</td>
              
              <td>{{ CalculateDuration(RS.duration) }}</td>
              <td>{{ RS.fareBasis }}</td>
              <td>{{ RS.seatsRemain }}</td>            
              <td>{{ RS.stops }}</td>
            </tr>
          </tbody>
        </table>

        <div class="accordion" :id="'RulesAccordian' + iNum">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingOne' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + iNum" aria-expanded="false" aria-controls="flush-collapseOne">Baggage Rules</button>
            </h2>
            <div :id="'collapseOne' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingOne' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Segment</th>
                      <th>Adult Quantity</th>
                      <th>Adult Weight</th>
                      <th>Child Quantity</th>
                      <th>Child Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="B in VerifiedFlight.rule.baggage" :key="iNum + 'bag' + B.segmentNo">                      
                      <td>{{ B.segmentNo }}</td>
                      <td>{{ B.adultQuantity }}</td>
                      <td>{{ B.adultWeight }}</td>
                      <td>{{ B.childQuantity }}</td>
                      <td>{{ B.childWeight }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingTwo' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + iNum" aria-expanded="false" aria-controls="flush-collapseTwo">Change Rules</button>
            </h2>
            <div :id="'collapseTwo' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingTwo' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">USD Fee</th>
                      <th scope="col">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(C, iLoop) in VerifiedFlight.rule.changes" :key="iNum + 'change' + iLoop">

                      <td v-if="C.changeType === 0">All Unused</td>
                      <td v-else-if="C.changeType === 1">Partial Used</td>
                      <td v-else-if="C.changeType === 2">No-Show Before Takeoff</td>
                      <td v-else-if="C.changeType === 3">No-Show After Takeoff</td>
                      <td v-else>{{ C.changeType }}</td>

                      <td class="text-danger" v-if="C.changeStatus === 'T'">Non Refundable</td>
                      <td class="text-primary" v-else-if="C.changeStatus === 'H'">Conditions Apply</td>
                      <td class="text-success" v-else-if="C.changeStatus === 'F'">Refundable</td>
                      <td v-else-if="C.changeStatus === 'E'">Airline Rules</td>

                      <td>{{C.changeFee}}</td>
                      <td>{{ C.remark }}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingThree' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseThree' + iNum" aria-expanded="false" aria-controls="flush-collapseThree">Refund Rules</button>
            </h2>
            <div :id="'collapseThree' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingThree' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">USD Fee</th>
                      <th scope="col">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(R, iLoop) in VerifiedFlight.rule.refunds" :key="iNum + 'refund' + iLoop">

                      <td v-if="R.refundType === 0">All Unused</td>
                      <td v-else-if="R.refundType === 1">Partial Used</td>
                      <td v-else-if="R.refundType === 2">No-Show Before Takeoff</td>
                      <td v-else-if="R.refundType === 3">No-Show After Takeoff</td>
                      <td v-else>{{ R.refundType }}</td>

                      <td class="text-danger" v-if="R.refundStatus === 'T'">Non Refundable</td>
                      <td class="text-primary" v-else-if="R.refundStatus === 'H'">Conditions Apply</td>
                      <td class="text-success" v-else-if="R.refundStatus === 'F'">Refundable</td>
                      <td v-else-if="R.refundStatus === 'E'">Airline Rules</td>

                      <td>{{ R.refundFee }}</td>
                      <td>{{ R.remark }}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingFour' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFour' + iNum" aria-expanded="false" aria-controls="flush-collapseFour">Other Information</button>
            </h2>
            <div :id="'collapseFour' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingFour' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <ul class="list-group">

                  <li v-if="VerifiedFlight.routing.nationalityType === 0" class="list-group-item">Suitable For All Nationalities</li>
                  <li v-else-if="VerifiedFlight.routing.nationalityType === 1" class="list-group-item">Suitable For The Below Nationalities</li>
                  <li v-else-if="VerifiedFlight.routing.nationalityType === 2" class="list-group-item list-group-item-danger">Not Suitable For The Below Nationalities</li>
                  <li v-else class="list-group-item">Nationality Type: {{ VerifiedFlight.routing.nationalityType }}</li>

                  <li v-if="VerifiedFlight.routing.nationality" class="list-group-item">Nationalities: {{ Verified.routing.nationality }}</li>

                  <li v-if="VerifiedFlight.routing.suitAge" class="list-group-item">Suitable Ages: {{ VerifiedFlight.routing.suitAge }}</li>
                  <li v-else class="list-group-item list-group-item-success">Suitable For All Ages</li>

                </ul>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header h4">Flight Ancillaries</div>
      <div class="card-body">
        <h5 class="card-title">Extras such as meals and baggage will be added at a later stage</h5>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header h4">Create Booking</div>
      <div class="card-body">

        <form @submit.prevent="HandleCreateBooking">

          <div v-if="SearchData.NumberAdults" v-for="(iNum, iLoop) in parseInt(SearchData.NumberAdults)">

            <div class="row mb-3">
              <div class="col-sm-12"><h4 class="text-color-prim">Adult Passenger: {{ iNum }}</h4></div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-3 mb-3">
                <label class="form-label">Name</label>
                <input v-model="InputAdultPaxArray[iLoop].FirstName" required type="text" class="form-control">
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Surname</label>
                <input v-model="InputAdultPaxArray[iLoop].LastName" type="text" class="form-control" required>
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Date Of Birth</label>
                <flat-pickr v-model="InputAdultPaxArray[iLoop].birthday" ref="DepartureDatePicker" class="form-control" :config="dateconfig"></flat-pickr>
              </div> 
              <div class="col-sm-3 mb-3">
                <label class="form-label">Gender</label>
                <select v-model="InputAdultPaxArray[iLoop].gender" required class="form-select">
                  <option value="U" selected>Not Specified</option>
                  <option value="F">Female</option>
                  <option value="M">Male</option>                  
                </select>
              </div>      
            </div>

            <div class="row mb-3">

              <div class="col-sm-3 mb-3">
                <label class="form-label">Passenger Nationality</label>
                <SimpleTypeahead
                    class="form-control"                
                    placeholder="Search For A Country"
                    :items="CountriesList"
                    :minInputLength="3"                
                    @selectItem="HandleAdultNationalitySelect($event, iLoop)"
                    :itemProjection="itemProjectionFunction"
                  >
                <template #list-header><span class="text-color-prim">Search By Name Or Part Thereof</span></template>
                </SimpleTypeahead>  
                <!-- <input v-model="InputAdultPaxArray[iLoop].nationality" type="text" class="form-control" required> -->
              </div>
              <div class="col-sm-2 mb-3">                
                <label class="form-label">Document Type</label>
                <select v-model="InputAdultPaxArray[iLoop].cardType" class="form-select" required>
                  <option value="PP" selected>Passport</option>
                  <option value="ID">Chinese Identity Card</option>                                   
                </select>
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Document Number</label>
                <input v-model="InputAdultPaxArray[iLoop].cardNum" type="text" class="form-control" required>
              </div>
              <div class="col-sm-2 mb-3">
                <label class="form-label">Document Expiry</label>
                <flat-pickr v-model="InputAdultPaxArray[iLoop].cardExpired" ref="DepartureDatePicker" class="form-control" :config="dateconfig2"></flat-pickr>
              </div> 
              <div class="col-sm-2 mb-3">
                <label class="form-label">Document Country of Issue</label>
                <SimpleTypeahead
                    class="form-control"                
                    placeholder="Search For A Country"
                    :items="CountriesList"
                    :minInputLength="3"                
                    @selectItem="HandleAdultCOISelect($event, iLoop)"
                    :itemProjection="itemProjectionFunction"
                  >
                <template #list-header><span class="text-color-prim">Search By Name Or Part Thereof</span></template>
                </SimpleTypeahead> 
                <!-- <input v-model="InputAdultPaxArray[iLoop].cardIssuePlace" type="text" class="form-control" required> -->
              </div>              

            </div>  

      
          </div>

          <hr>

          <div v-if="SearchData.NumberChilds" v-for="(iNum, iLoop) in parseInt(SearchData.NumberChilds)">
            
            <div class="row mb-3">
              <div class="col-sm-12"><h4 class="text-secondary">Child Passenger: {{ iNum }}</h4></div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-3 mb-3">
                <label class="form-label">Name</label>
                <input v-model="InputChildPaxArray[iLoop].FirstName" required type="text" class="form-control">
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Surname</label>
                <input v-model="InputChildPaxArray[iLoop].LastName" type="text" class="form-control" required>
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Date Of Birth</label>
                <flat-pickr v-model="InputChildPaxArray[iLoop].birthday" ref="DepartureDatePicker" class="form-control" :config="dateconfig"></flat-pickr>
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Gender</label>
                <select v-model="InputChildPaxArray[iLoop].gender" class="form-select" required>
                  <option value="U" selected>Not Specified</option>
                  <option value="F">Female</option>
                  <option value="M">Male</option>                  
                </select>
              </div>      
            </div>

            <div class="row mb-3">

              <div class="col-sm-3 mb-3">
                <label class="form-label">Passenger Nationality</label>    
                <SimpleTypeahead
                    class="form-control"                
                    placeholder="Search For A Country"
                    :items="CountriesList"
                    :minInputLength="3"                
                    @selectItem="HandleChildNationalitySelect($event, iLoop)"
                    :itemProjection="itemProjectionFunction"
                  >
                <template #list-header><span class="text-color-prim">Search By Name Or Part Thereof</span></template>
                </SimpleTypeahead>                 
                <!-- <input v-model="InputChildPaxArray[iLoop].nationality" type="text" class="form-control" required> -->
              </div>
              <div class="col-sm-2 mb-3">                
                <label class="form-label">Document Type</label>
                <select v-model="InputChildPaxArray[iLoop].cardType" class="form-select" required>
                  <option value="PP" selected>Passport</option>
                  <option value="ID">Chinese Identity Card</option>                                   
                </select>
              </div>
              <div class="col-sm-3 mb-3">
                <label class="form-label">Document Number</label>
                <input v-model="InputChildPaxArray[iLoop].cardNum" type="text" class="form-control" required>
              </div>
              <div class="col-sm-2 mb-3">
                <label class="form-label">Document Expiry</label>
                <flat-pickr v-model="InputChildPaxArray[iLoop].cardExpired" ref="DepartureDatePicker" class="form-control" :config="dateconfig2"></flat-pickr>
              </div> 
              <div class="col-sm-2 mb-3">
                <label class="form-label">Document Country of Issue</label>
                <SimpleTypeahead
                    class="form-control"                
                    placeholder="Search For A Country"
                    :items="CountriesList"
                    :minInputLength="3"                
                    @selectItem="HandleChildCOISelect($event, iLoop)"
                    :itemProjection="itemProjectionFunction"
                  >
                <template #list-header><span class="text-color-prim">Search By Name Or Part Thereof</span></template>
                </SimpleTypeahead> 
                <!-- <input v-model="InputChildPaxArray[iLoop].cardIssuePlace" type="text" class="form-control" required> -->
              </div>              

            </div>  

          </div>
          
          <hr>

          <div class="row mb-3">
            <div class="col-sm-12"><h4 class="text-dark">Contact Details</h4></div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4 mb-3">
              <label class="form-label">Name</label>
              <input v-model="Input_ContactName" type="text" class="form-control" required>
            </div>
            <div class="col-sm-4 mb-3">
              <label class="form-label">Address</label>
              <input v-model="Input_ContactAddress" type="text" class="form-control" required>
            </div>
            <div class="col-sm-4 mb-3">
              <label class="form-label">Post Code</label>
              <input v-model="Input_ContactPostCode" type="text" class="form-control" required>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-6 mb-3">
              <label class="form-label">Email</label>
              <input v-model="Input_ContactEmail" type="email" class="form-control" required>
            </div>
            <div class="col-sm-6 mb-3">
              <label class="form-label">Mobile</label>
              <input v-model="Input_ContactMobile" type="text" class="form-control" required>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12 mb-3">
              <div class="alert alert-warning">Please make sure all route and passenger information is correct. Any changes after this step could be subject to a fee.</div>
            </div>
          </div>
          
          <button ref="btnCreateBooking" type="submit" class="btn btn-primary btn-color-1 mb-3">
            <div v-if="bLoading">
              <VueSpinnerDots size="30" color="white" />
            </div>
            <div v-else>Create Booking</div>
          </button> 

        </form>

        
      </div>
    </div>

  </div>
</template>

<script>  

import { computed, onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
import { UseFlightSearchStore } from "@/stores/FlightSearchStore";
const dayjs = require('dayjs')
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { VueSpinnerDots } from 'vue3-spinners';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS
const Countries = require('../assets/countries.json')


export default {
  components: {flatPickr, VueSpinnerDots, SimpleTypeahead},
    setup(){ 

      const FlightSearchStore = UseFlightSearchStore()
      
      const SearchData = ref({})
      const VerifiedFlight = ref()
      const iNum = 0

      const InputAdultPaxArray = ref([])
      const InputChildPaxArray = ref([])
      
      //Contact fields
      const Input_ContactName = ref('Remi Ayasaki')
      const Input_ContactAddress = ref('64 Zoo Lane')
      const Input_ContactPostCode = ref('6464')
      const Input_ContactEmail = ref('manabdu786@outlook.com')
      const Input_ContactMobile = ref('+27677997891')

      const ErrorMessage = ref('')

      const btnCreateBooking = ref(null)

      const dateconfig = ref({
        mode: 'single',        
        dateFormat: 'Ymd',        
        altFormat: 'd F Y',
        altInput: true,
        defaultDate: '19700101'
      })

      const dateconfig2 = ref({
        mode: 'single',        
        dateFormat: 'Ymd',        
        altFormat: 'd F Y',
        altInput: true,
        defaultDate: '20320101'
      })

      const bLoading = ref(false)

      const CountriesList = computed(() => {

        var arrCountries = []

        Countries.forEach((C) => {
          const obj = {
            'name': C.Name,
            'value': C.Code
          }
          arrCountries.push(obj)
        })

        return arrCountries

      })

      onMounted(() => {

        if(Object.keys(FlightSearchStore.VerifiedFlight).length < 1){          
          router.push({name: 'search'})
        }

        SearchData.value = FlightSearchStore.SearchData
        VerifiedFlight.value = FlightSearchStore.VerifiedFlight

        //initialise arrays for pax data
        const iNumAdults = parseInt(SearchData.value.NumberAdults)
        
        for(var i = 0; i < iNumAdults; i++){
          const Obj = {
            FirstName: "",
            LastName: "",
            ageType: "0",
            birthday: "",
            gender: "",
            cardType: "",
            cardNum: "",
            cardExpired: "",
            cardIssuePlace: "",
            nationality: ""
          }
          InputAdultPaxArray.value.push(Obj)
        }

        const iNumChilds = parseInt(SearchData.value.NumberChilds)
        
        for(var i = 0; i < iNumChilds; i++){
          const Obj = {
            FirstName: "",
            LastName: "",
            ageType: "1",
            birthday: "",
            gender: "",
            cardType: "",
            cardNum: "",
            cardExpired: "",
            cardIssuePlace: "",
            nationality: ""
          }
          InputChildPaxArray.value.push(Obj)
        }

        
        // //Ancillary Search
        // const FD = VerifiedFlight.value
        // axios.post("/api/SearchExtras", {FD}).then((Result) => {
        //   console.log(Result);
        // }).catch((Error) => {
        //   console.log(Error)
        //   if(Error.response.data.Reason){
        //     ErrorMessage.value = Error.response.data.Reason
        //   }
        // })


      })//end mounted

      const CalculateDuration = (Minutes) => {

        const iMinTotal = parseInt(Minutes)

        const iHours =  Math.floor(iMinTotal / 60)
        const iMinutes = iMinTotal % 60

        return iHours + "H & " + iMinutes + "M"

      }

      const TotalPrice = computed(() => {

        var dTotal = 0

        const iNumAdults = parseInt(SearchData.value.NumberAdults)
        const iNumChilds = parseInt(SearchData.value.NumberChilds) 

        var dTotalAdults = (parseFloat(VerifiedFlight.value.routing.adultPrice) + parseFloat(VerifiedFlight.value.routing.adultTax)) * parseInt(iNumAdults)
        var dTotalChilds = (parseFloat(VerifiedFlight.value.routing.childPrice) + parseFloat(VerifiedFlight.value.routing.childTax)) * parseInt(iNumChilds)
        dTotal = dTotalAdults + dTotalChilds

        return dTotal

      })

      const HandleCreateBooking = () => {

        //Check all inputs done in the backend

        const FD = VerifiedFlight.value
        const frmSearchData = SearchData.value

        //Combine Adults and childs into one array
        const frmPaxData = InputAdultPaxArray.value.concat(InputChildPaxArray.value);

        //Setup name field
        frmPaxData.forEach((Pax) => {
          Pax.name = Pax.LastName.toUpperCase() + "/" + Pax.FirstName
        })

        const frmContactData = {
          name: Input_ContactName.value,
          address: Input_ContactAddress.value,
          postcode: Input_ContactPostCode.value,
          email: Input_ContactEmail.value,
          mobile: Input_ContactMobile.value
        }

        btnCreateBooking.value.disabled = true
        bLoading.value = true

        axios.post("/api/BookFlight", {
          FD,
          frmSearchData,
          frmPaxData,
          frmContactData
        }).then((Result) => {
          // btnCreateBooking.value.disabled = false          
          // bLoading.value = false

          setTimeout(() => {
            router.push({ path: '/booking/' + Result.data.Booking.pnrCode + "/" + Result.data.Booking.orderNo })
          }, 5000);
           
        }).catch((Error) => {
          console.log(Error.response);
          if(Error.response.data.Reason){
            ErrorMessage.value = Error.response.data.Reason
          }
          btnCreateBooking.value.disabled = false          
          bLoading.value = false
        })


      }

      const itemProjectionFunction = (e) => {
        return e.name
      }

      const HandleAdultNationalitySelect = (g, iLoop) => {
        InputAdultPaxArray.value[iLoop].nationality = g.value      
      }

      const HandleChildNationalitySelect = (g, iLoop) => {
        InputChildPaxArray.value[iLoop].nationality = g.value
      }

      const HandleAdultCOISelect = (g, iLoop) => {
        InputAdultPaxArray.value[iLoop].cardIssuePlace = g.value 
      }

      const HandleChildCOISelect = (g, iLoop) => {
        InputChildPaxArray.value[iLoop].cardIssuePlace = g.value
      }

    

      return {      
        ErrorMessage,
        SearchData,
        VerifiedFlight,
        dayjs,
        CalculateDuration,
        TotalPrice,
        iNum,
        dateconfig,
        dateconfig2,
        InputAdultPaxArray,
        InputChildPaxArray,
        HandleCreateBooking,
        bLoading,
        Input_ContactName,
        Input_ContactAddress,
        Input_ContactPostCode,
        Input_ContactEmail,
        Input_ContactMobile,
        btnCreateBooking,
        itemProjectionFunction,
        CountriesList,
        HandleAdultNationalitySelect,
        HandleChildNationalitySelect,
        HandleAdultCOISelect,
        HandleChildCOISelect
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

</style>