import { createRouter, createWebHistory } from 'vue-router'

//Authentication
import LoginView from '../views/authentication/LoginView.vue'

//Search
import SearchView from '../views/SearchView.vue'

//Result of search
import ResultView from '../views/ResultView.vue'

//Verify before booking
import VerifyFlightView from '../views/VerifyFlightView.vue'

//Bookings list
import BookingsView from '../views/BookingsView.vue'

//View a booking
import BookingView from '../views/BookingView.vue'

//View an issued booking
import IssuedBookingView from '../views/IssuedBookingView.vue'

//Past bookings list (archived)
import PastBookingsView from '../views/PastBookingsView.vue'


import axios from "axios";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {title: "Login | ABD Flights"}
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: {title: "Search | ABD Flights"}
  },
  {
    path: '/results',
    name: 'searchresults',
    component: ResultView,
    meta: {title: "Results | ABD Flights"}
  },
  {
    path: '/verify',
    name: 'verifyflight',
    component: VerifyFlightView,
    meta: {title: "Verify Flight | ABD Flights"}
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: BookingsView,
    meta: {title: "My Bookings | ABD Flights"}
  },
  {
    path: '/booking/:pnr/:order',
    name: 'booking',
    component: BookingView,
    meta: {title: "View Booking | ABD Flights"}
  },
  {
    path: '/issued/:pnr/:order',
    name: 'issuedbooking',
    component: IssuedBookingView,
    meta: {title: "Issued Booking | ABD Flights"}
  },
  {
    path: '/archive',
    name: 'archivebookings',
    component: PastBookingsView,
    meta: {title: "Archived Bookings | ABD Flights"}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  document.title = to.meta.title;
  
  var bResult = false;

  let prom1 = axios.get('/api/IsLoggedIn').then((Result) => {
    if(Result.data.Success){
      bResult = true
    }
  }).catch((Error) => {
    console.log(Error)
  })

  Promise.all([prom1]).then(() => {

    if(to.name === 'login'){

      if(bResult){
        //We are logged in
        next({name: 'search'})
      }else{
        next();
      }
      
    } else if(to.name === 'register'){

      if(bResult){
        next({name: 'home'})
      }else{
        next()
      }

    }else if(to.name === 'forgotpassword'){

      if(bResult){
        next({name: 'home'})
      }else{
        next()
      }

    }else if(!bResult){
      next({name: 'login'})
    }else{
      next()
    }
  })

})


export default router