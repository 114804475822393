<template>
  <div class="table-view auth-form">
    
    <div class="card mb-3">
      <h5 class="card-header">{{ iNumResults }} Results - Filters - <button type="button" @click="HandleGoBack" class="btn btn-primary btn-color-2">Change Search Query</button></h5>
      <div class="card-body">

        <div v-if="ErrorMessage" class="row">
          <div class="col-sm-3">
            <div class="alert alert-danger">{{ ErrorMessage }}</div>
          </div>
        </div>

        <div class="row">

          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">Carrier</span>
              <select v-model="Input_CarrierSelect" class="form-select">
                <option value="any" selected>Any</option>
                <option v-for="Carrier in arrCarriers" :key="Carrier" :value="Carrier">{{ Carrier }}</option>
              </select>
              <span class="input-group-text">Strict?</span>
              <div class="input-group-text">
                <input v-model="Input_StrictCarrier" class="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input">
              </div>            
            </div>            
          </div>

          <div class="col-sm-3">
            <div class="input-group mb-3">
              <label class="input-group-text">Price</label>
              <select v-model="Input_PriceFilter" class="form-select">
                <option value="DEF" selected>Default</option>
                <option value="ASC">Lowest First</option>
                <option value="DESC">Highest First</option>                
              </select>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="input-group mb-3">
              <label class="input-group-text">Cabin</label>
              <select v-model="Input_CabinFilter" class="form-select">
                <option value="DEF" selected>Any</option>
                <option value="E">Economy</option>
                <option value="B">Business</option>  
                <option value="F">First</option>               
              </select>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="input-group mb-3">
              <label class="input-group-text">Departure Time</label>
              <select v-model="Input_DepTime" class="form-select">
                <option value="DEF" selected>Any</option>
                <option value="1">00:00 AM To 06:00 AM</option>
                <option value="2">06:01 AM To 12:00 PM </option>  
                <option value="3">12:01 PM To 18:00 PM</option>
                <option value="4">18:01 PM To 23:59 PM</option>
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-for="(FD, iNum) in FilteredFlightsArray" :key="FD.data" class="card mb-3">
      <div class="card-header">
        <table class="table table-border-prim">
          <thead>
            <tr>              
              <th>Departure</th>
              <th>Route</th>
              <th>Return</th>
              <th>Adult EA</th>
              <th>Adult Tax EA</th>
              <th>Child EA</th>
              <th>Child Tax EA</th>
              <th>Adults</th>
              <th>Childs</th>
              <th>Total USD</th>
              <th>Policy</th>
              <th>Fare</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dayjs(SearchData.DepartureDate).format("DD MMMM YYYY") }}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-sm btn-color-1">{{ SearchData.DepartureAirport }}</button>                  
                  <button type="button" class="btn btn-sm btn-color-2">{{ SearchData.ArrivalAirport }}</button>
                </div>
              </td>
              <td><span v-if="SearchData.ReturnDate">{{ dayjs(SearchData.ReturnDate).format("DD MMMM YYYY") }}</span></td>
              <td>{{ FD.adultPrice }}</td>
              <td>{{ FD.adultTax }} <span v-if="FD.adultTaxType === 0" class="badge rounded-pill badge-bg-prim">Excl</span> <span v-else-if="FD.adultTaxType === 1" class="badge rounded-pill badge-bg-sec">Incl</span> <span v-else class="badge rounded-pill badge-bg-primary">{{ FD.adultTaxType }}</span></td>
              <td>{{ FD.childPrice }}</td>
              <td>{{ FD.childTax }} <span v-if="FD.childTaxType === 0" class="badge rounded-pill badge-bg-prim">Excl</span> <span v-else-if="FD.childTaxType === 1" class="badge rounded-pill badge-bg-sec">Incl</span> <span v-else class="badge rounded-pill badge-bg-primary">{{ FD.childTaxType }}</span></td>
              <td>{{ SearchData.NumberAdults }}</td>
              <td>{{ SearchData.NumberChilds }}</td>

              <td>{{FD.TotalPrice.toFixed(2)}}</td>

              <td v-if="FD.policyCategory === 0"><span class="badge rounded-pill bg-warning text-dark">Special Offer</span></td>
              <td v-else-if="FD.policyCategory === 1"><span class="badge rounded-pill bg-primary">Standard</span></td>
              <td v-else-if="FD.policyCategory === 2"><span class="badge rounded-pill badge-bg-prim">Special Product</span></td>
              <td v-else>{{ FD.policyCategory }}</td>

              <td v-if="FD.productType === 'PUB'"><span class="badge rounded-pill bg-light text-dark">Public</span></td>
              <td v-else-if="FD.productType === 'PRV'"><span class="badge rounded-pill bg-dark">Private</span></td>
              <td v-else>{{ FD.productType }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <h5 class="card-title text-color-prim">Departure Segments</h5>
        <table class="table table-bordered table-border-prim">
          <thead>
            <tr>
              <th>Segment #</th>
              <th>Carrier</th>
              <th>Flight</th>              
              <th>Departure Time</th>
              <th>Departure Airport</th>
              <th>Arrival Time</th>
              <th>Arrival Airport</th>              
              <th>Cabin</th>
              <th>Duration</th>
              <th>Fare Basis</th>
              <th>Seats Left</th>
              <th>Stops</th>              
            </tr>
          </thead>
          <tbody>
            <tr class="table-row-hover" :class="DS.codeShare ? 'color-bg-2' : ''" v-for="(DS, iLoop) in FD.fromSegments" :key="iLoop + DS.flightNumber">
              <th>{{ iLoop + 1 }}</th>
              <td>{{ DS.carrier }}</td>
              <td>{{ DS.flightNumber }} <span class="badge badge-bg-sec">{{ DS.operatingFlightNumber }}</span></td>
              <td>{{ dayjs(DS.depTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(DS.depTime).format("HH mm") }}</span></td>
              <td>{{ DS.depAirport }}</td>
              <td>{{ dayjs(DS.arrTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(DS.arrTime).format("HH mm") }}</span></td>
              <td>{{ DS.arrAirport }}</td>
              
              <td v-if="DS.cabinGrade === 'Y'">{{ DS.cabin }} <span class="badge rounded-pill badge-bg-prim">Economy</span></td>
              <td v-else-if="DS.cabinGrade === 'F'">{{ DS.cabin }} <span class="badge rounded-pill bg-danger">First</span></td>
              <td v-else-if="DS.cabinGrade === 'C'">{{ DS.cabin }} <span class="badge rounded-pill bg-warning">Business</span></td>
              <td v-else>{{ DS.cabin }} | {{ DS.cabinGrade }}</td>

              <td>{{ CalculateDuration(DS.duration) }}</td>
              <td>{{ DS.fareBasis }}</td>
              <td>{{ DS.seatsRemain }}</td>            
              <td>{{ DS.stops }}</td>
            </tr>
          </tbody>
        </table>

        <h5 v-if="FD.retSegments.length > 0" class="card-title text-color-sec">Return Segments</h5>
        <table v-if="FD.retSegments.length > 0" class="table table-bordered table-border-prim">
          <thead>
            <tr>
              <th>Segment #</th>
              <th>Carrier</th>
              <th>Flight</th>              
              <th>Departure Time</th>
              <th>Departure Airport</th>
              <th>Arrival Time</th>
              <th>Arrival Airport</th>              
              <th>Cabin</th>
              <th>Duration</th>
              <th>Fare Basis</th>
              <th>Seats Left</th>
              <th>Stops</th>              
            </tr>
          </thead>
          <tbody>
            <tr class="table-row-hover" :class="RS.codeShare ? 'color-bg-2' : ''" v-for="(RS, iLoop) in FD.retSegments" :key="iLoop + RS.flightNumber">
              <th>{{ FD.fromSegments.length + (iLoop + 1) }}</th>
              <td>{{ RS.carrier }}</td>
              <td>{{ RS.flightNumber }} <span class="badge badge-bg-sec">{{ RS.operatingFlightNumber }}</span></td>
              <td>{{ dayjs(RS.depTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(RS.depTime).format("HH mm") }}</span></td>
              <td>{{ RS.depAirport }}</td>
              <td>{{ dayjs(RS.arrTime).format("DD MMM YYYY") }} <span class="badge badge-bg-sec">{{ dayjs(RS.arrTime).format("HH mm") }}</span></td>
              <td>{{ RS.arrAirport }}</td>

              <td v-if="RS.cabinGrade === 'Y'">{{ RS.cabin }} <span class="badge rounded-pill badge-bg-prim">Economy</span></td>
              <td v-else-if="RS.cabinGrade === 'F'">{{ RS.cabin }} <span class="badge rounded-pill bg-danger">First</span></td>
              <td v-else-if="RS.cabinGrade === 'C'">{{ RS.cabin }} <span class="badge rounded-pill bg-warning">Business</span></td>
              <td v-else>{{ RS.cabin }} | {{ RS.cabinGrade }}</td>
              
              <td>{{ CalculateDuration(RS.duration) }}</td>
              <td>{{ RS.fareBasis }}</td>
              <td>{{ RS.seatsRemain }}</td>            
              <td>{{ RS.stops }}</td>
            </tr>
          </tbody>
        </table>

        <div class="accordion" :id="'RulesAccordian' + iNum">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingOne' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + iNum" aria-expanded="false" aria-controls="flush-collapseOne">Baggage Rules</button>
            </h2>
            <div :id="'collapseOne' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingOne' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Segment</th>
                      <th>Adult Quantity</th>
                      <th>Adult Weight</th>
                      <th>Child Quantity</th>
                      <th>Child Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="B in FD.rule.baggage" :key="iNum + 'bag' + B.segmentNo">                      
                      <td>{{ B.segmentNo }}</td>
                      <td>{{ B.adultQuantity }}</td>
                      <td>{{ B.adultWeight }}</td>
                      <td>{{ B.childQuantity }}</td>
                      <td>{{ B.childWeight }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingTwo' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + iNum" aria-expanded="false" aria-controls="flush-collapseTwo">Change Rules</button>
            </h2>
            <div :id="'collapseTwo' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingTwo' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">USD Fee</th>
                      <th scope="col">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(C, iLoop) in FD.rule.changes" :key="iNum + 'change' + iLoop">

                      <td v-if="C.changeType === 0">All Unused</td>
                      <td v-else-if="C.changeType === 1">Partial Used</td>
                      <td v-else-if="C.changeType === 2">No-Show Before Takeoff</td>
                      <td v-else-if="C.changeType === 3">No-Show After Takeoff</td>
                      <td v-else>{{ C.changeType }}</td>

                      <td class="text-danger" v-if="C.changeStatus === 'T'">Non Refundable</td>
                      <td class="text-primary" v-else-if="C.changeStatus === 'H'">Conditions Apply</td>
                      <td class="text-success" v-else-if="C.changeStatus === 'F'">Refundable</td>
                      <td v-else-if="C.changeStatus === 'E'">Airline Rules</td>

                      <td>{{C.changeFee}}</td>
                      <td>{{ C.remark }}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingThree' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseThree' + iNum" aria-expanded="false" aria-controls="flush-collapseThree">Refund Rules</button>
            </h2>
            <div :id="'collapseThree' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingThree' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">USD Fee</th>
                      <th scope="col">Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(R, iLoop) in FD.rule.refunds" :key="iNum + 'refund' + iLoop">

                      <td v-if="R.refundType === 0">All Unused</td>
                      <td v-else-if="R.refundType === 1">Partial Used</td>
                      <td v-else-if="R.refundType === 2">No-Show Before Takeoff</td>
                      <td v-else-if="R.refundType === 3">No-Show After Takeoff</td>
                      <td v-else>{{ R.refundType }}</td>

                      <td class="text-danger" v-if="R.refundStatus === 'T'">Non Refundable</td>
                      <td class="text-primary" v-else-if="R.refundStatus === 'H'">Conditions Apply</td>
                      <td class="text-success" v-else-if="R.refundStatus === 'F'">Refundable</td>
                      <td v-else-if="R.refundStatus === 'E'">Airline Rules</td>

                      <td>{{ R.refundFee }}</td>
                      <td>{{ R.remark }}</td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" :id="'headingFour' + iNum">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFour' + iNum" aria-expanded="false" aria-controls="flush-collapseFour">Other Information</button>
            </h2>
            <div :id="'collapseFour' + iNum" class="accordion-collapse collapse" :aria-labelledby="'headingFour' + iNum" :data-bs-parent="'#RulesAccordian' + iNum">
              <div class="accordion-body">
                <ul class="list-group">

                  <li v-if="FD.nationalityType === 0" class="list-group-item">Suitable For All Nationalities</li>
                  <li v-else-if="FD.nationalityType === 1" class="list-group-item">Suitable For The Below Nationalities</li>
                  <li v-else-if="FD.nationalityType === 2" class="list-group-item list-group-item-danger">Not Suitable For The Below Nationalities</li>
                  <li v-else class="list-group-item">Nationality Type: {{ FD.nationalityType }}</li>

                  <li v-if="FD.nationality" class="list-group-item">Nationalities: {{ FD.nationality }}</li>

                  <li v-if="FD.suitAge" class="list-group-item">Suitable Ages: {{ FD.suitAge }}</li>
                  <li v-else class="list-group-item list-group-item-success">Suitable For All Ages</li>

                </ul>
              </div>
            </div>
          </div>




        </div>


      </div>
      <div class="card-footer">
        <button @click="HandleAddToCart(FD, $event)" type="button" class="btn btn-primary btn-color-1">          
          Select This Itinerary
        </button>         
      </div>
    </div>     
              
  </div>
</template>

<script>

import { computed, onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
import { UseFlightSearchStore } from "@/stores/FlightSearchStore";
const dayjs = require('dayjs')
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default {
  components: {},
    setup(){ 

      const FlightSearchStore = UseFlightSearchStore()
      const arrAirportCodes = ref([])
      const arrFlights = ref([])      

      const Input_CarrierSelect = ref('any')
      const Input_StrictCarrier = ref(false)
      const Input_PriceFilter = ref('DEF')
      const Input_CabinFilter = ref('DEF')
      const Input_DepTime = ref('DEF')

      const SearchData = ref({})          

      const ErrorMessage = ref('')

      onMounted(() => {

        //check if we have airport codes and check if we have flight routings
        // if(FlightSearchStore.AirportCodes.length < 1){
        //   router.push({name: 'search'})
        // }

        if(FlightSearchStore.FlightRoutings.length < 1){
          router.push({name: 'search'})
        }

        arrAirportCodes.value = FlightSearchStore.AirportCodes
        arrFlights.value = FlightSearchStore.FlightRoutings

        SearchData.value = FlightSearchStore.SearchData

        //console.log(arrFlights.value[0].rule.refunds);

      })

      const arrCarriers = computed(() => {

        var Carriers = []

        arrFlights.value.forEach((F) => {
          
          F.fromSegments.forEach((FS) => {
            Carriers.push(FS.carrier)
          })

          F.retSegments.forEach((RT) => {
            Carriers.push(RT.carrier)
          })
          
        })

        //Thanks stackoverflow
        return Carriers.filter((value, index, array) => array.indexOf(value) === index);

      })

      const FilteredFlightsArray = computed(() => {
        
        var arrFilteredFlights = []
        
        if(Input_CarrierSelect.value === "any") arrFilteredFlights = arrFlights.value

        arrFlights.value.forEach((F) => {

          //Check if strict filter or not
          if(Input_StrictCarrier.value){

            var bIsMyCarrierFlight = true          
          
            //Check from segments
            F.fromSegments.forEach((FS) => {            
              if(FS.carrier !== Input_CarrierSelect.value) bIsMyCarrierFlight = false
            })

            //Check return segements
            F.retSegments.forEach((RT) => {
              if(RT.carrier !== Input_CarrierSelect.value) bIsMyCarrierFlight = false
            })

            if(bIsMyCarrierFlight) arrFilteredFlights.push(F)

          }else{

            //Not strict

            var bIsMyCarrierFlight = false

            //Check from segments
            F.fromSegments.forEach((FS) => {            
              if(FS.carrier == Input_CarrierSelect.value) bIsMyCarrierFlight = true
            })

            //Check return segements
            F.retSegments.forEach((RT) => {
              if(RT.carrier == Input_CarrierSelect.value) bIsMyCarrierFlight = true
            })

            if(bIsMyCarrierFlight) arrFilteredFlights.push(F)


          }//end strict if
                    

        })//end foreach
        
        //Calculate total price of option
        arrFilteredFlights.forEach((FF) => {
          FF.TotalPrice = CalculateTotalPriceOfOption(FF.adultPrice, FF.adultTax, FF.childPrice, FF.childTax)
        })

        switch(Input_PriceFilter.value){
          case 'ASC' : {
            arrFilteredFlights = arrFilteredFlights.sort((a, b) => {
              return parseFloat(a.TotalPrice) - parseFloat(b.TotalPrice)
            })
          }break
          case 'DESC' : {
            arrFilteredFlights = arrFilteredFlights.sort((a, b) => {
              return parseFloat(b.TotalPrice) - parseFloat(a.TotalPrice)
            })
          }break
          default : {     

          }
        }

        //Cabin filter

        var TempCabinFilterArrayIndexes = []
        var bCabinFilter = true

        switch(Input_CabinFilter.value){
          case 'E' : {            
            
            arrFilteredFlights.forEach((FF, iIndex) => {

              var bCanAdd = false              

              FF.fromSegments.forEach((FS) => {                
                if(FS.cabinGrade === 'Y') bCanAdd = true
              })

              FF.retSegments.forEach((RS) => {
                if(RS.cabinGrade === 'Y') bCanAdd = true
              })

              if(bCanAdd){                
                TempCabinFilterArrayIndexes.push(iIndex)
              } 

            })            

          }break
          case 'B' : {            
            arrFilteredFlights.forEach((FF, iIndex) => {              

              var bCanAdd = false

              FF.fromSegments.forEach((FS) => {                
                if(FS.cabinGrade === 'C') bCanAdd = true
              })

              FF.retSegments.forEach((RS) => {                
                if(RS.cabinGrade === 'C') bCanAdd = true
              })
              
              if(bCanAdd) TempCabinFilterArrayIndexes.push(iIndex)

            })            

          }break
          case 'F' : {            
            arrFilteredFlights.forEach((FF, iIndex) => {

              var bCanAdd = false

              FF.fromSegments.forEach((FS) => {
                if(FS.cabinGrade === 'F') bCanAdd = true
              })

              FF.retSegments.forEach((RS) => {
                if(RS.cabinGrade === 'F') bCanAdd = true
              })

              if(bCanAdd) TempCabinFilterArrayIndexes.push(iIndex)

              })
          }break
          default : bCabinFilter = false          
        }
                
        if(bCabinFilter) arrFilteredFlights =  TempCabinFilterArrayIndexes.map(i => arrFilteredFlights[i])

        //Departure times filter

        var TempDepFilterArrayIndexes = []
        var bDepFilter = true

        switch(Input_DepTime.value){
          case "1" : {            

            arrFilteredFlights.forEach((FF, iIndex) => {                        
              
              //Look at only the first segment of the departure
              const FS = FF.fromSegments[0]

              const dOriginalTime = dayjs(FS.depTime)
              const dBeginTime = dOriginalTime.set('hour', 0).set('minute', 0)
              const dEndTime = dOriginalTime.set('hour', 6).set('minute', 0)
                              
              if(dayjs(FS.depTime).isBetween(dBeginTime, dEndTime, 'hour', '[)')) TempDepFilterArrayIndexes.push(iIndex)

            })

          }break
          case "2" : {

            arrFilteredFlights.forEach((FF, iIndex) => {                          

              //Look at only the first segment of the departure
              const FS = FF.fromSegments[0]

              const dOriginalTime = dayjs(FS.depTime)
              const dBeginTime = dOriginalTime.set('hour', 6).set('minute', 1)
              const dEndTime = dOriginalTime.set('hour', 12).set('minute', 0)
                            
              if(dayjs(FS.depTime).isBetween(dBeginTime, dEndTime, 'hour', '[)')) TempDepFilterArrayIndexes.push(iIndex)

            })
          
          }break
          case "3" : {

            arrFilteredFlights.forEach((FF, iIndex) => {

              //Look at only the first segment of the departure
              const FS = FF.fromSegments[0]             

              const dOriginalTime = dayjs(FS.depTime)
              const dBeginTime = dOriginalTime.set('hour', 12).set('minute', 1)
              const dEndTime = dOriginalTime.set('hour', 18).set('minute', 0)
                              
              if(dayjs(FS.depTime).isBetween(dBeginTime, dEndTime, 'hour', '[)')) TempDepFilterArrayIndexes.push(iIndex)

            })
            

          }break
          case "4" : {

            arrFilteredFlights.forEach((FF, iIndex) => {

              //Look at only the first segment of the departure
              const FS = FF.fromSegments[0]                

              const dOriginalTime = dayjs(FS.depTime)
              const dBeginTime = dOriginalTime.set('hour', 18).set('minute', 1)
              const dEndTime = dOriginalTime.set('hour', 23).set('minute', 59)
                  
              if(dayjs(FS.depTime).isBetween(dBeginTime, dEndTime, 'hour', '[)')) TempDepFilterArrayIndexes.push(iIndex)

            })            

          }break
          default : bDepFilter = false
        }


        if(bDepFilter) arrFilteredFlights =  TempDepFilterArrayIndexes.map(i => arrFilteredFlights[i])

        return arrFilteredFlights
     

      })

      const CalculateDuration = (Minutes) => {

        const iMinTotal = parseInt(Minutes)

        const iHours =  Math.floor(iMinTotal / 60)
        const iMinutes = iMinTotal % 60

        return iHours + "H & " + iMinutes + "M"

      }

      const CalculateTotalPriceOfOption = (sAdultPriceEA, sAdultTaxEA, sChildPriceEA, sChildTaxEA) => {

        var dTotal = 0

        const iNumAdults = parseInt(SearchData.value.NumberAdults)
        const iNumChilds = parseInt(SearchData.value.NumberChilds)        

        var dTotalAdults = (parseFloat(sAdultPriceEA) + parseFloat(sAdultTaxEA)) * parseInt(iNumAdults)
        var dTotalChilds = (parseFloat(sChildPriceEA) + parseFloat(sChildTaxEA)) * parseInt(iNumChilds)
        dTotal = dTotalAdults + dTotalChilds

        return dTotal

      }

      const iNumResults = computed(() => {
        return FilteredFlightsArray.value.length
      })

      const HandleGoBack = () => {
        router.back()
      }

      const HandleAddToCart = (FD, e) => {

        const frmSearchData = SearchData.value
        
        e.target.innerText = 'Please Wait...'
        e.target.disabled = true

        axios.post("/api/VerifyFlight", {FD, frmSearchData}).then((Result) => {
          FlightSearchStore.VerifiedFlight = Result.data.VerifiedFlight
          router.push({name: 'verifyflight'})          
        }).catch((Error) => {
          console.log(Error);  
          e.target.innerText = 'Error'
          e.target.disabled = true        
          if(Error.response.data.Reason){
            ErrorMessage.value = Error.response.data.Reason
          }
        })

      }


      return {                    
        ErrorMessage,
        SearchData,
        FilteredFlightsArray,
        arrCarriers,    
        arrFlights,  
        Input_CarrierSelect,
        Input_StrictCarrier,
        Input_PriceFilter,
        Input_CabinFilter,
        Input_DepTime,        
        CalculateDuration,
        CalculateTotalPriceOfOption,
        HandleGoBack,
        HandleAddToCart,        
        iNumResults,
        dayjs
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.table-row-hover:hover{
  background-color: #B2A4FF;
  color: white !important;  
}

</style>