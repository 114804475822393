<template>
  <div class="table-view auth-form">
    
    <div class="card mb-3">
      <h5 class="card-header">My Bookings - Filters</h5>
      <div class="card-body">

        <div v-if="ErrorMessage" class="row">
          <div class="col-sm-3">
            <div class="alert alert-danger">{{ ErrorMessage }}</div>
          </div>
        </div>

      </div>
    </div>  

    <div class="card mb-3">      
      <div class="card-body">

        <table class="table table-border-prim">
          <thead>
            <tr>              
              <th>Date</th>
              <th>Flight Date</th>
              <th>PNR</th>
              <th>Carrier</th>
              <th>Routing</th>
              <th>Pax</th>
              <th>Status</th>              
              <th>TTL Minutes</th>
              <th>Minutes Left</th>
              <th>Fee</th>
              <th>Total USD</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="B in Bookings" :key="B._id">
              <td>{{ dayjs(B.FBDateTime).format("DD MMMM YYYY HH:mm") }}</td>
              <td>{{ dayjs(B.FBResObject.routing.fromSegments[0].depTime).format("DD MMMM YYYY HH:mm") }}</td>
              <td>{{ B.FBResObject.pnrCode.toUpperCase() }}</td>
              <td>{{ B.FBResObject.routing.validatingCarrier }}</td>
              <td> 
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li :key="iLoop" v-for="(R, iLoop) in (GetRoutingShortForm(B.FBResObject.routing))" class="breadcrumb-item active" aria-current="page">{{R}}</li>
                  </ol>
                </nav>
              </td>
              <td>{{ GetPaxFromRouting(B.FBReqObject.passengers) }}</td>

              <td v-if="B.FBStatus === 'PAYMENTDUE'"><button type="button" class="btn btn-sm btn-color-2">Payment Due</button></td>
              <td v-else-if="B.FBStatus === 'PENDING'"><button type="button" class="btn btn-sm btn-color-1">To Be Issued</button></td>
              <td v-else-if="B.FBStatus === 'ISSUED'"><button type="button" class="btn btn-sm btn-color-1">Confirmed/Issued</button></td>
              <td v-else-if="B.FBStatus === 'CANCELLED'"><button type="button" class="btn btn-sm btn-secondary">Cancelled</button></td>
              <td v-else-if="B.FBStatus === 'PROBLEM'"><button type="button" class="btn btn-sm btn-danger">Error</button></td>
              <td v-else><button type="button" class="btn btn-info">Unknown</button></td>

              <td v-if="CalculateTTL(B.FBDateTime, B.FBResObject.routing.ticketTimeLimit) > 60"><button type="button" class="btn btn-sm btn-color-1">{{ CalculateTTL(B.FBDateTime, B.FBResObject.routing.ticketTimeLimit) }}</button></td>
              <td v-else-if="CalculateTTL(B.FBDateTime, B.FBResObject.routing.ticketTimeLimit) < 1"><button type="button" class="btn btn-sm btn-outline-danger">N/A</button></td>
              <td v-else><button type="button" class="btn btn-sm btn-color-2">{{ CalculateTTL(B.FBDateTime, B.FBResObject.routing.ticketTimeLimit) }}</button></td>
             
              <td v-if="CalculateValidity(B.FBDateTime) > 10"><button type="button" class="btn btn-sm btn-color-1">{{ CalculateValidity(B.FBDateTime) }}</button></td>
              <td v-else-if="CalculateValidity(B.FBDateTime) > 1 && CalculateValidity(B.FBDateTime) < 10"><button type="button" class="btn btn-sm btn-color-1">{{ CalculateValidity(B.FBDateTime) }}</button></td>
              <td v-else><button type="button" class="btn btn-sm btn-outline-danger">N/A</button></td>

              <td>{{ B.FBTicketingFee }}</td>
              <td>{{ B.FBTotalPrice }}</td>

              <td>                
                <router-link class="btn btn-sm btn-color-3" :to="'/booking/' + B.FBResObject.pnrCode + '/' + B.FBResObject.orderNo">Manage</router-link>
              </td>

            </tr>
          </tbody>
        </table>

      </div>
    </div>  
            
  </div>
</template>

<script>

import { computed, onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
import { UseFlightSearchStore } from "@/stores/FlightSearchStore";
const dayjs = require('dayjs')
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default {
  components: {},
    setup(){ 

      const FlightSearchStore = UseFlightSearchStore()

      const ErrorMessage = ref('')
      const Bookings = ref([])
      

      onMounted(() => {

        //check if we have airport codes and check if we have flight routings
        // if(FlightSearchStore.AirportCodes.length < 1){
        //   router.push({name: 'search'})
        // }

        //Get bookings
        axios.get("/api/GetMyBookings").then((Result) => {
          Bookings.value = Result.data.Bookings
        }).catch((Error) => {
          if(Error.response.data.Reason){
            ErrorMessage.value = Error.response.data.Reason
          }
        })

      })

      const GetRoutingShortForm = (Routing) => {

        var arrRouting = []

        //From Segments
        Routing.fromSegments.forEach((FS) => {
          arrRouting.push(FS.depAirport)
          arrRouting.push(FS.arrAirport)
        })

        //Return Segments
        Routing.retSegments.forEach((RS) => {
          arrRouting.push(RS.depAirport)
          arrRouting.push(RS.arrAirport)
        })        

        return arrRouting

      }

      const GetPaxFromRouting = (Pax) => {

        var iNumAdults = 0
        var iNumChilds = 0

        Pax.forEach((P) => {
          if(P.ageType === "0") iNumAdults++
          if(P.ageType === "1") iNumChilds++
        })

        var sPax = iNumAdults + "A"

        if(iNumChilds > 0){
          sPax += " & " + iNumChilds + "C"
        }
        
        return sPax

      }

      const CalculateTTL = (DateTime, TTL) => {
        const dBookingExpiry = dayjs(DateTime).add(parseInt(TTL), 'minutes')
        const dTTL = dBookingExpiry.diff(dayjs(), 'minutes')

        return parseInt(dTTL)
      }

      const CalculateValidity = (DateTime) => {
        const dBookingExpiry = dayjs(DateTime).add(parseInt(30), 'minutes')
        const dTTL = dBookingExpiry.diff(dayjs(), 'minutes')

        return parseInt(dTTL)
      }




      return {                    
        ErrorMessage,
        Bookings,
        dayjs,
        GetRoutingShortForm,
        GetPaxFromRouting,
        CalculateTTL,
        CalculateValidity
      }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.table-row-hover:hover{
  background-color: #B2A4FF;
  color: white !important;  
}

</style>